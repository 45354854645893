conversationUrlUid<template> 
  <div class="mt-4 ml-5 mr-5">
    <div v-show="DV_isLoading">
      <h2 class="text-xl mt-32 text-gray-400">Please wait. Connecting...</h2>
      <svg class="h-12 mt-2 text-gray-400 spin-clockwise mx-auto" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
        <path stroke-linecap="round" stroke-linejoin="round" d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0l3.181 3.183a8.25 8.25 0 0013.803-3.7M4.031 9.865a8.25 8.25 0 0113.803-3.7l3.181 3.182m0-4.991v4.99" />
      </svg>
    </div>
    
    <div v-if="!DV_isLoading" class="flex flex-col text-xl items-center gap-3">
      <h1 class="font-bold py-2 text-center text-xl">
        Enter Contact Information 
      </h1>

      <div class="sm:w-full w-3/4">
        <span>Name</span>
        <input v-model="DV_fullName" type="text"
          class="col-span-5 border border-gray-400 rounded-md px-2 py-1 focus:outline-none text-center"
          placeholder="Your Name"/>
        <span class="text-center text-red-500 text-xl">
          {{ DV_NameError }}
        </span>
      </div>


      <div class="sm:w-full w-3/4">
        <span> Phone Number </span>
        <input v-model="DV_phoneNumber" type="number" inputmode="numeric"
          class="col-span-5 border border-gray-400 rounded-md px-2 py-1 focus:outline-none text-center"
          placeholder="Your Phone Number"/>
        <span class="text-center text-red-500 text-xl">
          {{ DV_phoneNumberError }}
        </span>
      </div>
      <div class="mt-5">
        <button
          @click="submit()"
          class="btn btn-lime text-xl flex items-center justify-center mx-auto w-[68vw]"
        >
          {{ DV_isQuickQueueEntry ? "Connect With A Live Representative" : "Next" }}
        </button>
      </div>

      <div class="flex items-center">
        <input type="checkbox" id="terms_agreement" name="terms_agreement" v-model="DV_agreement" class="h-4 mr-2 mt-0 w-4">
        <label v-if="isIframeActive" for="terms_agreement" class="cursor-pointer text-sm text-left">I agree to the terms & conditions stated below</label>
        <label v-else for="terms_agreement" class="cursor-pointer text-sm text-left">I agree to the terms & conditions stated below.</label>
      </div>

      <p class="text-sm text-left">
        By continuing after this page, you consent to receive automated text messages and calls from an autodialer system or live person. Consent to receive automated text alerts not required for purchase. Msg & Data Rates May Apply. Reply 'HELP' for HELP, 'STOP ALL' to cancel. <a class="text-blue-500" href="https://synergy-auto-solutions.com/use">Terms apply upon use</a>.
      </p>
    </div>
  </div>
</template>

<script>
import queueConsumerConversation from "../../includes/server_calls/p1s3/p1s3e16";
import dataValidator from "../../includes/bi1Datavalidation";
import {CR,RC} from "../../includes/bi2SuccessCodes"

export default {
  name: "c1c10GetConsumerDetails",
  
  props: [ "P_businessUid", "P_recyclerLocationUid", "P_customerType" ],
  
  data() {
    return {
      DV_isLoading: true,
      DV_fullName: "",
      DV_phoneNumber: "",
      DV_NameError: "",
      DV_phoneNumberError: "",
      DV_agreement: false,
      isIframeActive: window.isIframeActive,
      DV_recycler_information: {},
      DV_isQuickQueueEntry: true,
      DV_quickQueueEntryDataChanged: false,
    };
  },

  mounted() {
    this.startRecyclerInformationListener();

    //load cached data
    this.DV_fullName = window.localStorage.getItem("c1c10_full_name") || "";
    this.DV_phoneNumber = window.localStorage.getItem("c1c10_phone_number") || "";

  },

  methods: {
    startRecyclerInformationListener(retry_count=0) {
      const CI = this;

      if (Object.keys(this.DV_recycler_information).length === 0) {
        this.DV_recycler_information = window.G_bi2_data.IV_recycler_information.value;
        
        setTimeout(function() { CI.startRecyclerInformationListener(++retry_count) }, 200);
        return;
      }

      if ("quick_queue_entry" in this.DV_recycler_information && this.DV_recycler_information.quick_queue_entry === false) {
        this.DV_isQuickQueueEntry = false;
      } else {
        this.DV_isQuickQueueEntry = true;
        
        setTimeout(() => {
          this.DV_isLoading = false;
        }, 3000);
        
      }
    },
    
    // Makeshift input validation, should probably be replaced later
    submit() {
      const CI = this;
      CI.DV_NameError = "";
      CI.DV_phoneNumberError = "";
      
      let fail_flag = false;
      let call_result = {};
      let first_name = "";
      let last_name = "";

      if (!CI.DV_agreement) {
        CI.$awn.alert("You must agree to the terms first");
        return;
      }

      //split full name so we don't have to have 2 fields
      let name_array = CI.DV_fullName.split(" ");
      for(let index in name_array) {
        if(index === 0 || index === '0') {
          first_name = name_array[index];
          continue;
        }
        last_name += name_array[index] + " ";
      }

      if(first_name === "") {
        first_name = CI.DV_fullName;
      }
      if (last_name === "") {
        last_name = "- Unknown Last Name";
      }

      call_result = dataValidator.is_string(CI.DV_fullName, 3, 40);
        if (call_result[CR.success] !== RC.success) {
          CI.DV_NameError = "Name is required and should be between 3 and 40 letters.";
          fail_flag = true;
        }


      call_result = dataValidator.is_string(CI.DV_phoneNumber, 9, 10);
      if (call_result[CR.success] !== RC.success) {
        CI.DV_phoneNumberError = "Phone number should be 10 digits";
        fail_flag = true;
      }

      call_result = dataValidator.is_us_phone_number(CI.DV_phoneNumber);
      if (call_result[CR.success] !== RC.success) {
        CI.DV_phoneNumberError = "Valid US phone number is required.";
        fail_flag = true;
      }

      if (fail_flag) {
        return;
      }
      
      window.localStorage.setItem("c1c10_full_name",CI.DV_fullName);
      window.localStorage.setItem("c1c10_phone_number",CI.DV_phoneNumber);

      // process quick queue entry or request vehicle information
      if (this.DV_isQuickQueueEntry === true) {
        let params = {
          recycler_uid: this.P_businessUid,
          recycler_location_uid: this.P_recyclerLocationUid,
          conversation_info: {
            type: CI.P_customerType,
            first_name: first_name,
            last_name: last_name,
            vehiclesAndParts: [],
          },
          phone_number: CI.DV_phoneNumber
        }
       
        CI.DV_isLoading = true;

        queueConsumerConversation(params).then((resp) => {
          if("max_open_conversation_count_reached" in resp && resp["max_open_conversation_count_reached"] === true) {
            CI.DV_existingConversationDetails = resp["conversation_summary"];

            CI.DV_isLoading = false;
            CI.$emit(
              "next-step",
              first_name,
              last_name,
              CI.DV_phoneNumber,
              "too_many_active_conversations_error"
            );
          }  else if ("new_conversation_uid" in resp) {
            let conversationUrlUid = resp["new_conversation_uid"]
            
            CI.DV_isLoading = false;
            CI.$emit(
              "next-step",
              first_name,
              last_name,
              CI.DV_phoneNumber,
              "conversation_created_feedback",
              conversationUrlUid
            );
          }
        }).catch((error) => {
          CI.DV_isLoading = false;
          setTimeout(() => {
            CI.$awn.alert("Failed to start the conversation. Please try again.");
          }, 50);
        });

        return;
      }

      CI.$emit(
        "next-step",
        first_name,
        last_name,
        CI.DV_phoneNumber
      );
      
    },
  },

  watch: {
    DV_recycler_information: {
      handler() {
        const CI = this;
        
        CI.startRecyclerInformationListener();
      },
      deep: true
    },
    DV_isQuickQueueEntry: {
      handler() {
        this.DV_isLoading = false;
      }
    }
  }
};
</script>
